import React from "react";
import ReactDOM from "react-dom/client";
import CarListApp from "./CarListApp.jsx";

const container = document.querySelector("[data-w-car-list]");
const props = JSON.parse(container.getAttribute("data-w-car-list"));

ReactDOM.createRoot(container).render(
  // <React.StrictMode>
  <CarListApp {...props} />,
  // </React.StrictMode>
);
