// import clsx from "clsx";

export default function EmptyCarListItem() {
  return (
    <div className="tw-w-full tw-aspect-[4/5] tw-flex tw-flex-col tw-gap-4">
      <div className="tw-aspect-[4/3] tw-bg-gray-400 tw-bg-center tw-bg-no-repeat tw-bg-[length:20%_auto] tw-bg-[url('./images/placeholder.svg')] tw-mb-2"></div>
      <div className="tw-h-3 tw-w-3/4 tw-bg-gray-400"></div>
      <div className="tw-h-3 tw-w-1/2 tw-bg-gray-400"></div>
      <div className="tw-h-3 tw-w-2/3 tw-bg-gray-400"></div>
    </div>
  );
}
