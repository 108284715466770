import clsx from "clsx";

export default function Tag(props) {
  return (
    <div
      className={clsx(
        "tw-h-7 tw-px-3 tw-bg-blue tw-text-creme tw-rounded-full tw-inline-flex tw-justify-center tw-items-center tw-gap-2",
        props.className,
      )}
    >
      <div className="w-o-format-body-tiny tw-uppercase tw--mb-0.5">
        {props.text}
      </div>
    </div>
  );
}
