import { useEffect, useState, useMemo, useRef } from "react";

import Spinner from "./components/Spinner";
import EmptyCarListItem from "./components/EmptyCarListItem";
import CarListItem from "./components/CarListItem";
import Button from "./components/Button";
import clsx from "clsx";

import "./style.css";

import { register } from "swiper/element/bundle";
register();

function CarListApp(props) {
  const [cars, setCars] = useState(null);
  const [filter, setFilter] = useState(null);
  const [activeFilter, setActiveFilter] = useState(false);
  const [status, setStatus] = useState("loading");
  const [sorting, setSorting] = useState("date");
  const [showOverlay, setShowOverlay] = useState(false);

  const swiperRef = useRef();

  const type = props.type ?? "grid";
  const detailUrl = props.detail_url ?? "#";
  const title = props.title ?? null;
  const link = props.link ?? null;

  useEffect(() => {
    const event = new Event("carListApp.loaded");
    document.dispatchEvent(event);
  }, []);

  useEffect(() => {
    fetch("/wp-json/brne/v1/cars/")
      .then((response) => response.json())
      .then((result) => {
        setCars(result.cars);
        setFilter(result.filter);
        if (props.pre_filter && result.filter.includes(props.pre_filter))
          setActiveFilter(props.pre_filter);
        setStatus("loaded");
      });
  }, []);

  useEffect(() => {
    if (!cars || type !== "slider") return;

    const params = {
      injectStyles: [
        ":host { --swiper-theme-color: white; --swiper-pagination-bullet-width: 1.5rem; --swiper-pagination-bullet-height: 0.125rem; --swiper-pagination-bullet-border-radius: 0; --swiper-pagination-bullet-inactive-opacity: 0.5 }",
        ".swiper { padding-top: 1rem; }",
        ".swiper-pagination-bullets.swiper-pagination-horizontal { width: auto; right: auto; left: var(--gutter); top: 0; bottom: auto; }",
        ".swiper-pagination-bullet-active { --swiper-pagination-bullet-width: 4.25rem; }",
        ".swiper-wrapper { align-items: stretch; } .swiper-wrapper > slot { display: flex; }",
      ],
      slidesPerView: 1,
      breakpoints: {
        320: {
          slidesPerView: 1.25,
        },
        480: {
          slidesPerView: 2.5,
        },
        768: {
          slidesPerView: 3.5,
        },
        1024: {
          slidesPerView: 4.5,
        },
        1600: {
          slidesPerView: 5.5,
        },
      },
      spaceBetween: -24,
      pagination: {
        type: "bullets",
      },
    };
    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();
  }, [cars]);

  const sortedCars = useMemo(() => {
    if (status === "loading" || !cars) return [];

    if (sorting === "price")
      return cars.sort((a, b) => {
        return a.price - b.price;
      });
    else if (sorting === "date")
      return cars.sort((a, b) => {
        return b.key - a.key;
      });
    else return cars;
  }, [status, cars, sorting]);

  return (
    <div
      className={clsx([
        "tw-bg-gray tw-text-white tw-p-5 lg:tw-p-6 tw-pb-12 lg:tw-pb-16 tw-flex tw-flex-col tw-gap-6 tw-max-w-full tw-overflow-hidden",
        "w-b-car-list__inner",
      ])}
    >
      {type === "grid" && (
        <div className="tw-flex tw-flex-wrap tw-gap-3 tw-items-center tw-justify-between">
          <div className={clsx(["tw-flex tw-gap-3", "max-lg:tw-hidden"])}>
            <Button
              element="label"
              key="all"
              htmlFor="all"
              title="Alle Marken"
              state={activeFilter === false ? "active" : "idle"}
            >
              <input
                type="radio"
                name="filter"
                id="all"
                value="all"
                onChange={() => setActiveFilter(false)}
                checked={activeFilter === false}
                className="tw-invisible tw-w-0 tw-h-0 tw-overflow-clip tw-absolute"
              />
            </Button>
            {status === "loading" && <Spinner className="" />}
            {status === "loaded" &&
              filter &&
              filter.map((item) => (
                <Button
                  element="label"
                  key={item}
                  htmlFor={item}
                  title={item}
                  state={activeFilter === item ? "active" : "idle"}
                >
                  <input
                    type="radio"
                    name="filter"
                    id={item}
                    value={item}
                    onChange={() => setActiveFilter(item)}
                    checked={activeFilter === item}
                    className="tw-invisible tw-w-0 tw-h-0 tw-overflow-clip tw-absolute"
                  />
                </Button>
              ))}
          </div>
          {status === "loaded" && filter && (
            <div className="max-md:tw-w-full lg:tw-hidden w-select">
              <Button
                element="select"
                title="Marken"
                className="max-md:tw-w-full"
                onChange={(e) =>
                  setActiveFilter(
                    e.target.value === "all" ? false : e.target.value,
                  )
                }
                value={activeFilter}
              >
                <option value="all">Alle Marken</option>
                {filter.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Button>
            </div>
          )}
          <div className="max-md:tw-w-full md:tw-ml-auto w-select">
            <Button
              type="outlined"
              element="select"
              className="max-lg:tw-w-full"
              onChange={(e) => setSorting(e.target.value)}
            >
              <option value="date">Neueste zuerst</option>
              <option value="price">Günstigste zuerst</option>
            </Button>
          </div>
        </div>
      )}
      {type === "slider" && (
        <div className="tw-flex tw-flex-wrap tw-gap-5 tw-items-center tw-justify-between tw-pb-8">
          <div className="tw-max-w-screen-xs lg:tw-max-w-screen-sm w-o-format-h3">
            {title}
          </div>
          {status === "loaded" && link && (
            <Button element="a" href={link} title="Alle Fahrzeuge" />
          )}
        </div>
      )}
      <div>
        {status === "loading" && (
          <div className="tw-grid tw-grid-cols-[repeat(auto-fill,minmax(16rem,1fr))] tw-gap-8 tw-relative">
            <Spinner
              size="large"
              className="tw-absolute tw-top-4 tw-left-4 tw-z-10"
            />
            <EmptyCarListItem />
            <EmptyCarListItem />
            <EmptyCarListItem />
          </div>
        )}
        {status === "loaded" && type === "grid" && (
          <div className="tw-grid tw-grid-cols-[repeat(auto-fill,minmax(16rem,1fr))] tw-gap-8 tw-relative">
            {sortedCars.map((car, index) => (
              <CarListItem
                href={`${detailUrl}?id=${car.id}`}
                context={type}
                key={`car_${car.id}_${index}`}
                car={car}
                latest={index < 2}
                visible={
                  activeFilter === false ||
                  (activeFilter !== false && car.make === activeFilter)
                }
              />
            ))}
          </div>
        )}
        {status === "loaded" && type === "slider" && (
          <swiper-container ref={swiperRef} init="false" class="tw--m-6">
            {sortedCars.map((car, index) =>
              activeFilter === false ||
              (activeFilter !== false && car.make === activeFilter) ? (
                <swiper-slide
                  class="tw-flex-1 tw-flex tw-items-stretch tw-min-w-min"
                  key={`car_${car.id}_${index}`}
                >
                  <CarListItem
                    href={`${detailUrl}?id=${car.id}`}
                    context={type}
                    className=""
                    car={car}
                    latest={index < 2}
                    visible={
                      activeFilter === false ||
                      (activeFilter !== false && car.make === activeFilter)
                    }
                  />
                </swiper-slide>
              ) : null,
            )}
          </swiper-container>
        )}
      </div>
    </div>
  );
}

export default CarListApp;
